<template>
  <div>
    <portal to="header">
      <s-button
        v-if="id != '+'"
        icon="vmdi-plus"
        color="primary"
        size="sm"
        label="Add Theme"
        @click.native="theme('+')"
      />
    </portal>
    <s-row>
      <s-column :size="2">
        <item-form
          :get="get"
          :item="id"
          :fields="fields"
          :save="save"
          :del="del"
          class="p-4"
          ref="item"
          #default="{form,creating}"
          :redirect="false"
        >
          <s-field-validate
            label="Name"
            :rules="{ required: creating, moduleName: true }"
          >
            <s-textbox v-if="creating" v-model="form.name" />
            <h4 v-else>{{ form.name }}</h4>
          </s-field-validate>

          <s-field-validate :rules="{ required: creating }" label="Component">
            <s-file
              class="p-0"
              label="Select"
              color="primary"
              v-model="form.component"
              fluid
            />
          </s-field-validate>

          <tags type="module" v-model="form.tags" />

          <s-field inline label="Publish">
            <s-switch
              color="primary"
              size="xs"
              shape="pill"
              v-model="form.publish"
            />
          </s-field>
        </item-form>
      </s-column>
      <s-column :size="10">
        <sp-list
          v-if="id != '+'"
          title="Themes"
          :endpoint="`v1/admin/modules/${id}/themes`"
          :attrs="columns"
          ref="list"
        >
          <sp-list-table>
            <template #preview_image="{item}">
              <img width="400px" :src="item.preview_image" class="b-lightest" />
            </template>

            <template #preview_status="{item}">
              <node-status :status="item.preview_status" />
            </template>

            <template #preview_log="{item}">
              <pre class="text-xs">
                {{ item.preview_log }}
              </pre>
            </template>

            <template #actions="{item}">
              <s-button-group
                size="sm"
                color="primary"
                theme="muted"
                align="right"
                class="flex flex--column flex--fit"
              >
                <s-button
                  @click.native="theme(item.id)"
                  icon="vmdi-pencil"
                  label="Edit Theme"
                />

                <s-button
                  class="mt-1"
                  :href="`/module.html?name=${name}&theme=${item.name}`"
                  target="_blank"
                  icon="vmdi-open-in-new"
                  label="Open"
                />

                <s-button
                  class="mt-1"
                  color="warning"
                  icon="vmdi-reload"
                  label="Rebuild Preview"
                  @click.native="buildPreview(item.id)"
                />
              </s-button-group>
            </template>
          </sp-list-table>
        </sp-list>
      </s-column>
    </s-row>

    <!-- THEME -->
    <s-modal id="add-theme" overlay="dark" size="lg">
      <modal title="Add New Theme">
        <item-form
          :get="getTheme"
          :item="themeId"
          :fields="themeFields"
          :save="saveTheme"
          :del="del"
          class="p-4"
          ref="item"
          :redirect="false"
          #default="{form}"
        >
          <s-field label="Name">
            <s-textbox v-model="form.name" />
          </s-field>

          <s-field label="Segments JSON">
            <prism-editor
              class="code-editor radius-2"
              v-model="form.segments"
              :highlight="highlighter"
              line-numbers
            />
          </s-field>
        </item-form>
      </modal>
    </s-modal>
  </div>
</template>

<script>
import { saveTheme, getTheme, buildPreview } from "@/api/theme";
import { get, save, del } from "@/api/module";
import { highlight, languages } from "prismjs/components/prism-core";
import { toBase64 } from "@/utils";

export default {
  props: {
    id: [String, Number],
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    Tags: require("@/components/Tags").default,
    NodeStatus: require("@/components/NodeStatus").default,
  },
  data() {
    return {
      name: null,
      columns: [
        "name",
        { name: "preview_image" },
        { name: "preview_status" },
        { name: "preview_log" },
        {
          name: "actions",
          fix: true,
        },
      ],
      fields: [
        "id",
        "name",
        "component",
        "tags",
        "category",
        "publish",
        {
          name: "themes",
          value: [],
        },
      ],
      themeFields: ["name", "segments"],
      themeId: null,
    };
  },

  methods: {
    highlighter(code) {
      return highlight(code, languages.json); // languages.<insert language> to return html with markup
    },

    get(id) {
      return get(id).then((res) => {
        res.tags = res.tags.map((item) => item.id);
        this.name = res.name;
        return res;
      });
    },

    getTheme(id) {
      if (id) {
        return getTheme(this.id, id);
      }
    },

    async save(id, data) {
      const newData = { ...data };
      if (newData.component) {
        newData.component = await toBase64(newData.component[0]);
      }
      return save(id, newData).then((res) => {
        this.$router.push({
          name: "module",
          params: {
            id: res.id,
          },
        });
      });
    },

    saveTheme(id, data) {
      return saveTheme(this.id, id, data).then((res) => {
        this.$shilp.modal.close("add-theme");
        this.themeId = null;
        this.$refs.list.refresh();
      });
    },

    del(id) {
      return del(id).then(() => {
        this.$router.push({
          name: "modules",
        });
      });
    },

    theme(id) {
      this.themeId = id;
      this.$shilp.modal.open("add-theme");
    },

    buildPreview(id) {
      buildPreview(this.id, id)
        .then((res) => {
          this.$shilp.notify({
            title: "Success",
            type: "info",
            message: res.message,
          });
        })
        .catch((err) => {
          console.error(err);
          this.$shilp.notify({
            title: "Error!",
            type: "danger",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .options {
    display: flex;
    flex-direction: column;
    .choice {
      margin-bottom: --space(1);
    }
  }
}
</style>
