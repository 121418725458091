import axios from "@/plugins/axios";

export const save = (id, data) => {
  if (id) {
    return axios
      .put(`v1/admin/segment-preset/${id}`, data)
      .then((res) => res.data);
  } else {
    return axios.post(`v1/admin/segment-preset/`, data).then((res) => res.data);
  }
};

export const get = (id) => {
  return axios
    .get(`v1/admin/segment-preset/${id}`)
    .then((res) => res.data)
    .then((res) => {
      return {
        ...res,
        data: JSON.stringify(res.data, null, "\t"),
      };
    });
};

export const getSegments = () => {
  return axios
    .get(`v1/admin/segments`)
    .then((res) => res.data)
    .then((res) => {
      return res.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    });
};

export const del = (id) => {
  return axios.delete(`v1/admin/segment-preset/${id}`).then((res) => res.data);
};
