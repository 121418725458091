<template>
  <div>
    <sp-list
      class="bg-light"
      endpoint="v1/admin/campaigns"
      :attrs="columns"
      :params="params"
      :actions="['search', 'refresh', 'settings']"
    >
      <sp-list-table>
        <template #created_at="{item}">
          <datetime :value="item.created_at" />
        </template>
        <template #action="{item}">
          <!-- icon="vmdi-link" -->
          <s-button
            size="sm"
            class="mr-2"
            color="grey"
            theme="muted"
            align="right"
            label="View Page"
            :href="item.url"
            target="_blank"
          />
          <!-- icon="vmdi-open-in-new" -->
          <s-button
            size="sm"
            color="grey"
            theme="muted"
            align="right"
            label="View Draft"
            :href="item.draft_url"
            target="_blank"
          />
        </template>
      </sp-list-table>
    </sp-list>
  </div>
</template>
<script>
export default {
  components: {
    Datetime: require("@/components/Datetime").default,
  },
  data() {
    return {
      typeOptions: {
        landing_page: "Landing Page",
        product_page: "Product Page",
        promo_page: "Promo Page",
      },
      columns: [
        { name: "_index", label: "#", fix: true },
        { name: "name" },
        {
          name: "type",
          value: (item) => this.typeOptions[item.type],
          fix: true,
        },
        {
          name: "created_by",
          fix: true,
        },
        { name: "created_at", fix: true },
        { name: "action", fix: true },
      ],
    };
  },
  computed: {
    params() {
      return {
        append: "url,draft_url",
      };
    },
  },
};
</script>
