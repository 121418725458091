import Vue from "vue";
import SpListConfig from "./sp-list-plugin";

import ShilpVue, {
  ListPlugin,
  ListTable,
  FieldValidate,
  FormValidate,
} from "shilp-vue";

Vue.use(ShilpVue, {
  vueMaterialDesignIcons: require("@/icons").default,
});
Vue.use(ListPlugin, SpListConfig);
Vue.component("SFieldValidate", FieldValidate);
Vue.component("SFormValidate", FormValidate);
Vue.component("SpListTable", ListTable);
require("shilp-vue/dist/shilp-vue.css");
