import axios from "@/plugins/axios";

export const get = (id) => {
  return axios.get(`v1/admin/tags/${id}`).then((res) => res.data);
};

export const getTags = (type) => {
  return axios
    .get(`v1/admin/tags/`, {
      params: {
        per_page: -1,
        filter: {
          type,
        },
      },
    })
    .then((res) => res.data.data);
};

export const sort = (ids) => {
  return axios
    .put(`v1/admin/tags/sort`, {
      ids,
    })
    .then((res) => res.data);
};

export const save = (id, data) => {
  if (id) {
    return axios.put(`v1/admin/tags/${id}`, data).then((res) => res.data);
  } else {
    return axios.post(`v1/admin/tags`, data).then((res) => res.data);
  }
};

export const del = (id) => {
  return axios.delete(`v1/admin/tags/${id}`).then((res) => res.data);
};
