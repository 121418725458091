<template>
  <div class="flex bg-light flex--column flex--nowrap h-100 radius-4">
    <div v-if="header" class="flex__fit bg-light bb-lightest px-3 py-2">
      <div class="flex flex--middle ">
        <h4 class="flex__fluid">{{ title }}</h4>
        <div class="flex__fit">
          <s-button
            v-if="close"
            v-shilp-modal-close
            size="sm"
            shape="circle"
            theme="muted"
            color="grey"
            icon="vmdi-close"
          />
        </div>
      </div>
    </div>
    <div class="content flex__fluid" :class="bg">
      <slot></slot>
    </div>
    <div class="flex__fit">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  props: {
    title: String,
    header: {
      type: Boolean,
      default: true,
    },
    bg: {
      type: String,
      default: "bg-light",
    },
    close: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  overflow: auto;
}
</style>
