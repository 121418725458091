<template>
  <item-form
    :get="get"
    :item="id"
    :fields="fields"
    :save="save"
    :del="del"
    class="p-4"
    width="600px"
    ref="item"
    #default="{ form, creating }"
  >
    <s-field label="Template ID">
      <s-textbox v-if="creating" v-model="form.template_id" />
      <h3 v-else>{{ id }}</h3>
    </s-field>
    <s-field label="For amazon">
      <s-radio :options="types" v-model="form.for" />
    </s-field>
    <s-field label="Subscription Level">
      <s-radio :options="levels" v-model="form.allow_subscription_level" />
    </s-field>

    <tags type="template" v-model="form.tags" />
  </item-form>
</template>

<script>
import { get, save, del } from "@/api//template";

export default {
  props: {
    id: [String, Number],
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    Tags: require("@/components/Tags").default,
  },
  data() {
    return {
      fields: ["id", "template_id", "allow_subscription_level", "for", "tags"],
      levels: [
        {
          value: 2,
          label: "2",
        },
        {
          value: 3,
          label: "3",
        },
      ],
      types: [
        {
          value: "site",
          label: "Site template",
        },
        {
          value: "amazon",
          label: "Amazon template",
        },
      ],
    };
  },
  methods: {
    get(id) {
      return get(id).then((res) => {
        res.tags = res.tags.map((item) => item.id);
        return res;
      });
    },
    save(id, data) {
      return save(id, data);
    },
    del(id) {
      return del(id);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .options {
    display: flex;
    flex-direction: column;
    .choice {
      margin-bottom: --space(1);
    }
  }
}
</style>
