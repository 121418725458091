import axios from "@/plugins/axios";

export const list = () => {
  return axios.get("v1/media").then((res) => res.data);
};

export const upload = (form) => {
  var formData = new FormData();
  for (var key in form) {
    formData.append(key, form[key]);
  }
  return axios.post("/v1/media", formData).then((res) => res.data);
};

export const uploadBase64 = async (mediaSet) => {
  const mediaToUpload = [];
  for (var key in mediaSet) {
    if (mediaSet[key]) {
      mediaToUpload.push({
        key,
        value: mediaSet[key],
      });
    }
  }
  const Promises = mediaToUpload.map((item) => {
    return axios
      .post("/v1/media", { base64: item.value })
      .then((res) => res.data.id);
  });

  const idSet = {};
  if (Promises.length > 0) {
    const ids = await Promise.all(Promises);
    mediaToUpload.forEach((item, index) => {
      idSet[item.key] = ids[index];
    });
  }
  return idSet;
};

export const del = (id) => {
  return axios.delete(`/v1/media/${id}`).then((res) => res.data);
};
