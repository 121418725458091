import axios from "@/plugins/axios";

export const save = (id, data) => {
  if (id) {
    return axios
      .patch(`v1/admin/templates/${id}`, data)
      .then((res) => res.data);
  } else {
    return axios
      .post(`v1/admin/templates/save-as-pagemaker`, data)
      .then((res) => res.data);
  }
};

export const get = (id) => {
  return axios.get(`v1/admin/templates/${id}`).then((res) => res.data);
};

export const del = (id) => {
  return axios.delete(`v1/admin/templates/${id}`).then((res) => res.data);
};

export const buildThumb = (templateId) => {
  return axios
    .post(`/v1/admin/templates/generate-image/${templateId}`)
    .then((res) => res.data);
};
