import axios from "./axios";
import qs from "qs";

export default {
  requestHandler(payload) {
    const {
      method,
      endpoint,
      params,
      filters,
      search,
      pagination,
      sort,
    } = payload;
    // const filterQs = qs.stringify({ ...params, filters: filters });
    // console.log(filterQs);

    let sortBy;
    if (sort.order == "desc") {
      sortBy = "-" + sort.by;
    } else {
      sortBy = sort.by;
    }

    return axios[method](endpoint, {
      params: {
        ...params,
        search,
        filter: filters,
        page: pagination.page,
        per_page: pagination.perPage,
        sort: sortBy,
      },
      paramsSerializer: (params) => qs.stringify(params),
    })
      .then((res) => {
        return res.data;
      })
      .then((res) => {
        return {
          items: res.data,
          count: res.meta.total,
        };
      });
  },
  itemProps: {
    _index: {
      label: "#",
      width: "50px",
    },
  },
  typeAdapters: {
    currency(value, row) {
      return row.currency + " " + value.toLocaleString();
    },
    number(value) {
      return value.toLocaleString();
    },
  },
};
