<template>
  <div class="flex flex--middle flex--nowrap bb-lightest pr-2">
    <s-breadcrumbs class="flex__fit" :truncate="30"></s-breadcrumbs>
    <s-button
      class="flex__fit ml-3"
      v-if="addUrl"
      :to="addUrl"
      icon="plus"
      color="primary"
      theme="muted"
      shape="pill"
      size="sm"
      >{{ addLabel }}</s-button
    >
    <div class="flex__fit ml-auto">
      <portal-target name="header"></portal-target>
    </div>
  </div>
</template>

<script>
export default {
  name: "master-header",
  props: {
    title: String,
    addUrl: String,
    addLabel: {
      type: String,
      default: "Create",
    },
  },
};
</script>
