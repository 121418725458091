<template>
  <div>
    <portal to="header">
      <s-button
        color="primary"
        icon="vmdi-plus"
        :to="{ name: 'preset', params: { id: '+' } }"
        size="sm"
        label="Add New"
      />
    </portal>
    <sp-list
      class="bg-light"
      endpoint="v1/admin/segment-preset"
      :attrs="columns"
      :params="{ include: 'segment' }"
    >
      <sp-list-table sticky-header>
        <template #icon="{item}">
          <span class="iconify w-4 h-4 text-grey" :data-icon="item.icon" />
        </template>

        <template #preview="{item}">
          <img
            :src="item.preview_image"
            alt=""
            :width="item.preview_image_width / 2"
          />
        </template>

        <template #tags="{item}">
          <template v-for="tag in item.tags">
            <s-button
              :label="tag.name"
              :key="`tag-${tag.id}`"
              color="grey"
              theme="muted"
              shape="pill"
              size="xs"
              badge
            />
          </template>
        </template>

        <template #actions="{item}">
          <s-button
            icon="vmdi-pencil"
            color="grey"
            theme="muted"
            shape="circle"
            :to="{ name: 'preset', params: { id: item.id } }"
          />
          <s-button
            icon="vmdi-content-copy"
            color="grey"
            theme="muted"
            shape="pill"
            class="ml-1"
            @click.native="$shilp.copy(item.preset_id)"
            label="ID"
          />
          <s-button
            icon="vmdi-content-copy"
            color="grey"
            theme="muted"
            shape="pill"
            class="ml-1"
            @click.native="$shilp.copy(JSON.stringify(item.data, null, '\t'))"
            label="Props"
          />
        </template>
      </sp-list-table>
    </sp-list>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        { name: "_index", label: "#", fix: true },
        { name: "preview", fix: true },
        { name: "preset_id" },
        { name: "segment", value: (row) => row.segment.name },
        { name: "tags" },
        { name: "actions", fix: true },
      ],
    };
  },
};
</script>
