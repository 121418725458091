import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import privateRoutes from "./private";

const router = new Router({
  routes: [
    {
      path: "/",
      component: require("@/views/Auth").default,
      children: privateRoutes,
    },
    {
      path: "/login",
      name: "login",
      component: require("@/views/public/Login").default,
      meta: {
        public: true,
      },
    },
    {
      path: "/logout",
      name: "logout",
      component: require("@/views/public/Logout").default,
      meta: {
        public: true,
      },
    },
  ],
});
export default router;

router.beforeEach((from, to, next) => {
  if (from.meta.public) {
    next();
  } else {
    if (localStorage.getItem("token")) {
      next();
    } else {
      next({ name: "login" });
    }
  }
});
