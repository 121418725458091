<template>
  <!-- HYDRATED -->
  <s-layout
    class="layout--auth"
    v-if="hydrated"
    full-height
    :left-size="collapse ? '64px' : '200px'"
    push-top
  >
    <template #left>
      <master-sidebar :collapse="collapse" @collapse="collapse = $event" />
    </template>
    <template #top>
      <master-header />
    </template>

    <template #default>
      <router-view slot="main"></router-view>
    </template>
  </s-layout>

  <!-- HYDRATING -->
  <div v-else class="loading">
    <div class="logo">
      <img src="@/img/logo-icon.svg" />
    </div>
    <div class="mt-3 p-3" v-shilp-loader="true"></div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { setToken } from "@/plugins/axios";
import hydrate from "@/api/hydrate";

export default {
  name: "layout",
  components: {
    MasterSidebar: require("@/components/MasterSidebar").default,
    MasterHeader: require("@/components/MasterHeader").default,
  },

  computed: {
    ...mapGetters(["hydrating", "hydrated"]),
    ...mapState({
      user: (state) => state.user,
    }),
  },

  data() {
    return {
      collapse: false,
    };
  },

  created() {
    this.check();
    this.$root.$on("rehydrate", () => {
      this.check();
    });
  },
  methods: {
    ...mapActions(["hydrate"]),
    async check() {
      const token = window.localStorage.getItem("token");
      if (token) {
        setToken(token);
        try {
          const res = await hydrate();
          this.hydrate(res);
        } catch (err) {
          this.$shilp.notify({
            type: "danger",
            title: "Hydration Failed",
            message:
              "Kindly clear cache & try again. If problem persist, please contact us.",
          });
        }
      } else {
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    width: 100px;
    height: 100px;
    border: 1px solid --color(grey, lighter);
    border-radius: --radius(4);
    padding: --space(3);
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}
.layout--auth {
  grid-template-rows: var(--layout--top-size, minmax(0, max-content)) 1fr var(
      --layout--bottom-size,
      minmax(0, max-content)
    );
}
</style>
