<template>
  <item-form
    :get="get"
    :item="id"
    :fields="fields"
    :save="save"
    :del="del"
    class="p-4"
    width="400px"
    ref="item"
    :redirect-to="{ name: 'tags' }"
    #default="{ form }"
  >
    <s-field label="Name">
      <s-textbox v-model="form.name" />
    </s-field>
    <s-field label="Icon">
      <s-textbox v-model="form.icon" />
    </s-field>
    <s-field label="For">
      <s-radio :options="types" v-model="form.type" />
    </s-field>
  </item-form>
</template>

<script>
import { get, save, del } from "@/api//tag";

export default {
  props: {
    id: [String, Number],
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
  },
  data() {
    return {
      fields: ["name", "icon", "type"],
      types: [
        { label: "Module", value: "module" },
        { label: "Segment Preset", value: "segment-preset" },
        { label: "Template", value: "template" },
      ],
    };
  },
  methods: {
    get(id) {
      return get(id).then((res) => {
        return res;
      });
    },
    save(id, data) {
      return save(id, data);
    },
    del(id) {
      return del(id);
    },
  },
};
</script>
