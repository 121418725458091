<template>
  <item-form
    :get="get"
    :item="id"
    :fields="fields"
    :save="save"
    :del="del"
    class="p-4"
    width="400px"
    ref="item"
    :redirect-to="{ name: 'presets' }"
    #default="{form}"
  >
    <s-field-validate :rules="{ required: true, presetName: true }" label="ID">
      <s-textbox v-model="form.preset_id" />
    </s-field-validate>
    <s-field-validate rules="required" label="Segment">
      <s-select :request="getSegments" v-model="form.segment_id" />
    </s-field-validate>
    <tags type="segment-preset" v-model="form.tags" />
    <s-field-validate rules="required" label="Props">
      <prism-editor
        style="min-height:200px"
        class="code-editor radius-2"
        v-model="form.data"
        :highlight="highlighter"
        line-numbers
        :tab-size="4"
      />
    </s-field-validate>
  </item-form>
</template>

<script>
import { get, save, del, getSegments } from "@/api//preset";
import { highlight, languages } from "prismjs/components/prism-core";

export default {
  props: {
    id: [String, Number],
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    Tags: require("@/components/Tags").default,
  },
  data() {
    return {
      fields: ["preset_id", "segment_id", "data", "tags"],
      getSegments,
    };
  },

  methods: {
    highlighter(code) {
      return highlight(code, languages.json); // languages.<insert language> to return html with markup
    },

    get(id) {
      return get(id).then((res) => {
        res.tags = res.tags.map((item) => item.id);
        return res;
      });
    },
    save(id, data) {
      return save(id, data);
    },
    del(id) {
      return del(id);
    },
  },
};
</script>
