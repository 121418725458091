<template>
  <div>
    <portal to="header">
      <s-button
        color="primary"
        icon="vmdi-plus"
        :to="{ name: 'template', params: { id: '+' } }"
        size="sm"
        label="Add New"
      />
    </portal>
    <sp-list class="bg-light" endpoint="v1/admin/templates" :attrs="columns">
      <sp-list-table sticky-header>
        <template #preview_image="{item}">
          <div class="preview">
            <img :src="item.preview_image" />
          </div>
        </template>

        <template #updated_at="{item}">
          <datetime :value="item.updated_at" />
        </template>

        <template #details="{item}">
          <div class="p-3">
            <div>
              <h3>{{ item.name }}</h3>
              <p>{{ item.desc }}</p>
            </div>

            <div class="by-lighter py-2 my-2 list g-3">
              <div>
                <p class="text-grey">Template ID</p>
                <h5>{{ item.id }}</h5>
              </div>
              <div>
                <p class="text-grey">Level</p>
                <h5>{{ item.allow_subscription_level }}</h5>
              </div>
            </div>

            <s-button-group
              class="mt-2"
              color="primary"
              size="sm"
              theme="muted"
            >
              <s-button
                :to="{ name: 'template', params: { id: item.id } }"
                icon="vmdi-pencil"
                label="Edit"
              />
              <s-button
                :href="item.preview_image"
                target="_blank"
                class="ml-1"
                icon="vmdi-open-in-new"
                label="View Image"
              />
              <s-button
                :href="item.preview_url"
                target="_blank"
                class="ml-1"
                icon="vmdi-open-in-new"
                label="View HTML"
              />
              <s-button
                @click.native="generate(item.id)"
                class="ml-1"
                color="warning"
                icon="vmdi-reload"
                label="Regenerate Preview"
              />
            </s-button-group>
          </div>
        </template>
      </sp-list-table>
    </sp-list>
  </div>
</template>

<script>
import { buildThumb } from "@/api//template";

export default {
  data() {
    return {
      columns: [
        { name: "_index", label: "#", fix: true },
        { name: "preview_image", label: "Image", fix: true },
        { name: "details", label: "Details" },
        { name: "updated_at", fix: true },
      ],
    };
  },

  components: {
    Datetime: require("@/components/Datetime").default,
  },

  methods: {
    async generate(id) {
      try {
        await buildThumb(id);
        this.$shilp.notify({
          title: "Generating...",
          type: "info",
          message:
            "The request has been submitted to generate template thumb. It will take few seconds.",
        });
      } catch (err) {
        console.error(err);
        this.$shilp.notify({
          title: "Error!",
          type: "danger",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  width: 400px;
  height: 300px;
  overflow: auto;
  border-radius: --radius(3);
  border: 1px solid --color(grey, lighter);
  img {
    width: 100%;
  }
}
</style>
