var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sp-list',{staticClass:"bg-light",attrs:{"endpoint":"v1/admin/users","attrs":_vm.columns,"params":_vm.params,"actions":['search', 'refresh', 'settings']}},[_c('sp-list-table',{scopedSlots:_vm._u([{key:"email",fn:function(ref){
var item = ref.item;
return [_c('s-button',{class:item.email_verified_at ? 'text-success' : 'text-warning',attrs:{"size":"sm","label":item.email,"href":("mailto:" + (item.email)),"target":"_blank"}}),(item.google_provider_id != null)?_c('span',{staticClass:"iconify",attrs:{"data-icon":"akar-icons:google-contained-fill","data-width":"25","data-height":"25"}}):_vm._e(),(item.facebook_provider_id != null)?_c('span',{staticClass:"iconify ml-1",attrs:{"data-icon":"akar-icons:facebook-fill","data-width":"25","data-height":"25"}}):_vm._e()]}},{key:"email_verified_at",fn:function(ref){
var item = ref.item;
return [_c('datetime',{attrs:{"value":item.email_verified_at}})]}},{key:"last_login_at",fn:function(ref){
var item = ref.item;
return [_c('datetime',{attrs:{"value":item.last_login_at}})]}},{key:"subscription_plan_validity",fn:function(ref){
var item = ref.item;
return [_c('datetime',{attrs:{"value":item.subscription_plan_validity}})]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('datetime',{attrs:{"value":item.created_at}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }