<template>
  <div>
    <sp-list
      class="bg-light"
      endpoint="v1/admin/users"
      :attrs="columns"
      :params="params"
      :actions="['search', 'refresh', 'settings']"
    >
      <sp-list-table>
        <template #email="{ item }">
          <s-button
            :class="item.email_verified_at ? 'text-success' : 'text-warning'"
            size="sm"
            :label="item.email"
            :href="`mailto:${item.email}`"
            target="_blank"
          />
          <span
            v-if="item.google_provider_id != null"
            class="iconify"
            data-icon="akar-icons:google-contained-fill"
            data-width="25"
            data-height="25"
          />

          <span
            v-if="item.facebook_provider_id != null"
            class="iconify ml-1"
            data-icon="akar-icons:facebook-fill"
            data-width="25"
            data-height="25"
          />
        </template>
        <template #email_verified_at="{ item }">
          <datetime :value="item.email_verified_at" />
        </template>
        <template #last_login_at="{ item }">
          <datetime :value="item.last_login_at" />
        </template>
        <template #subscription_plan_validity="{ item }">
          <datetime :value="item.subscription_plan_validity" />
        </template>
        <template #created_at="{ item }">
          <datetime :value="item.created_at" />
        </template>
      </sp-list-table>
    </sp-list>
  </div>
</template>
<script>
// import { formatDistanceToNow } from "date-fns";
export default {
  components: {
    Datetime: require("@/components/Datetime").default,
  },
  data() {
    return {
      columns: [
        { name: "_index", label: "#", fix: true },
        { name: "name", fix: true },
        { name: "email", fix: true },
        {
          name: "subscriptionName",
          value: (item) => item?.subscription?.name,
          fix: true,
        },
        { name: "subscription_plan_validity", fix: true },
        { name: "last_login_at", fix: true },
        {
          name: "created_at",
          fix: true,
        },
      ],
    };
  },
  computed: {
    params() {
      return {
        include: "subscription",
      };
    },
  },
  methods: {
    // relativeValue(value) {
    //   if (value) {
    //     return formatDistanceToNow(new Date(value * 1000), { addSuffix: true });
    //   }
    //   return "";
    // },
  },
};
</script>
