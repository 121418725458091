export default {
  //Others
  "vmdi-window-close": require("@icon/WindowClose").default,
  "vmdi-creation": require("@icon/Creation").default,
  "vmdi-eyedropper": require("@icon/Eyedropper").default,
  "vmdi-information": require("@icon/Information").default,
  "vmdi-layers": require("@icon/Layers").default,
  "vmdi-rotate-right": require("@icon/RotateRight").default,
  "vmdi-twitter": require("@icon/Twitter").default,
  "image-auto-adjust": require("@icon/ImageAutoAdjust").default,
  "vmdi-circle-edit-outline": require("@icon/CircleEditOutline").default,
  "vmdi-plus": require("@icon/Plus").default,
  "vmdi-tag": require("@icon/Tag").default,
  "vmdi-minus": require("@icon/Minus").default,
  "vmdi-alert-circle": require("@icon/AlertCircle").default,
  "vmdi-alert": require("@icon/Alert").default,
  "vmdi-magnify": require("@icon/Magnify").default,
  "vmdi-file-edit": require("@icon/FileEdit").default,
  "vmdi-sync": require("@icon/Sync").default,
  "vmdi-toy-brick": require("@icon/ToyBrick").default,
  "vmdi-reload": require("@icon/Reload").default,
  "vmdi-desktop-mac-dashboard": require("@icon/DesktopMacDashboard").default,
  "vmdi-amazon": require("@icon/Amazon").default,
  "vmdi-newspaper-variant": require("@icon/NewspaperVariant").default,
  "vmdi-eye": require("@icon/Eye").default,
  "vmdi-eye-off": require("@icon/EyeOff").default,
  "vmdi-content-save-move": require("@icon/ContentSaveMove").default,
  "vmdi-check-decagram": require("@icon/CheckDecagram").default,
  "vmdi-web": require("@icon/Web").default,
  "vmdi-code-braces": require("@icon/CodeBraces").default,
  "vmdi-link": require("@icon/Link").default,
  "vmdi-arrow-expand-horizontal": require("@icon/ArrowExpandHorizontal")
    .default,
  "vmdi-folder-multiple-image": require("@icon/FolderMultipleImage").default,
  "vmdi-open-in-new": require("@icon/OpenInNew").default,
  "vmdi-language-javascript": require("@icon/LanguageJavascript").default,
  "vmdi-search-web": require("@icon/SearchWeb").default,
  "vmdi-palette": require("@icon/Palette").default,
  "vmdi-delete": require("@icon/Delete").default,
  "vmdi-content-save": require("@icon/ContentSave").default,
  "vmdi-email": require("@icon/Email").default,
  "vmdi-login-variant": require("@icon/LoginVariant").default,
  "vmdi-at": require("@icon/At").default,
  "vmdi-lock": require("@icon/Lock").default,
  "vmdi-lock-open": require("@icon/LockOpen").default,
  "vmdi-briefcase": require("@icon/Briefcase").default,
  "vmdi-account-circle": require("@icon/AccountCircle").default,
  "vmdi-logout-variant": require("@icon/LogoutVariant").default,
  "vmdi-square-edit-outline": require("@icon/SquareEditOutline").default,
  "vmdi-download": require("@icon/Download").default,
  "vmdi-file": require("@icon/File").default,
  "vmdi-earth": require("@icon/Earth").default,
  "vmdi-pause-circle": require("@icon/PauseCircle").default,
  "vmdi-alert-decagram": require("@icon/AlertDecagram").default,
  "vmdi-archive": require("@icon/Archive").default,
  "vmdi-sale": require("@icon/Sale").default,
  "vmdi-contacts": require("@icon/Contacts").default,
  "vmdi-newspaper-variant-multiple": require("@icon/NewspaperVariantMultiple")
    .default,
  "vmdi-content-copy": require("@icon/ContentCopy").default,
  "vmdi-checkbox-blank-outline": require("@icon/CheckboxBlankOutline").default,
  "vmdi-google-analytics": require("@icon/GoogleAnalytics").default,
  "vmdi-calendar-range": require("@icon/CalendarRange").default,
  "vmdi-creation": require("@icon/Creation").default,
  "vmdi-shield-account": require("@icon/ShieldAccount").default,
  "vmdi-upload": require("@icon/Upload").default,
  "vmdi-credit-card-outline": require("@icon/CreditCardOutline").default,
  "vmdi-dialpad": require("@icon/Dialpad").default,
  "vmdi-ticket-account": require("@icon/TicketAccount").default,
  "vmdi-star-circle": require("@icon/StarCircle").default,
  "vmdi-format-textbox": require("@icon/FormatTextbox").default,
  "vmdi-pencil-ruler": require("@icon/PencilRuler").default,
  "vmdi-arrow-collapse-right": require("@icon/ArrowCollapseRight").default,
  "vmdi-arrow-collapse-left": require("@icon/ArrowCollapseLeft").default,
  "vmdi-check": require("@icon/Check").default,
  "vmdi-cogs": require("@icon/Cogs").default,
  "vmdi-package-variant": require("@icon/PackageVariant").default,
  "vmdi-account-group": require("@icon/AccountGroup").default,
  "vmdi-briefcase": require("@icon/Briefcase").default,
};
