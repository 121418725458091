<template>
  <span
    v-if="value && relative"
    v-tooltip="{ content: tooltip ? dateValue : null }"
  >
    {{ relativeValue }} {{ suffix ? "ago" : null }}
  </span>
  <span v-else-if="value">{{ dateValue }} {{ suffix ? "ago" : null }}</span>
</template>

<script>
import { format, formatDistanceStrict } from "date-fns";

export default {
  props: {
    value: [Number, Boolean, String],
    relative: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
    suffix: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    relativeValue() {
      return formatDistanceStrict(this.value * 1000, new Date());
    },
    dateValue() {
      return format(this.value * 1000, "dd/MM/yyyy hh:mm:ss a");
    },
  },
};
</script>
