import Vue from "vue";
import "./vee-validate";

/**
 * Axios
 */
import axios from "./axios";
Vue.prototype.$axios = axios;

import draggable from "vuedraggable";
Vue.component("draggable", draggable);

/**
 * Import 7Span plugins conditionally.
 */
require("./7span");

/**
 * V Tooltip
 */
import VTooltip from "v-tooltip";
Vue.use(VTooltip);

/**
 * Vue Portal
 */
import PortalVue from "portal-vue";
Vue.use(PortalVue);

/**
 * VCalendar
 */
import VCalendar from "v-calendar";
Vue.use(VCalendar, {
  componentPrefix: "vc",
});

/**
 * Range Slider
 */
import VueSlider from "vue-slider-component";
Vue.component("VueSlider", VueSlider);

/**
 * Click Outside
 */
import vClickOutside from "v-click-outside";
Vue.use(vClickOutside);

/**
 * Prism Code Editor
 */
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles
Vue.component("PrismEditor", PrismEditor);
import "prismjs/components/prism-clike";
import "prismjs/components/prism-markup";
import "prismjs/components/prism-css";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-tomorrow.css";

/**
 * Cropper
 */
import VueCropper from "vue-cropperjs";
Vue.component("vue-cropper", VueCropper);
