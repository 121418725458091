<template>
  <s-field label="Tags" v-shilp-loader="!tags">
    <s-checkbox
      class="tags"
      gap="xs"
      :options="tags"
      :value="value"
      @input="$emit('input', $event)"
    >
    </s-checkbox>
  </s-field>
</template>

<script>
import { getTags } from "@/api/tag";

export default {
  props: {
    type: String,
    value: Array,
  },
  data() {
    return {
      tags: null,
    };
  },
  async created() {
    const tags = await getTags(this.type);
    this.tags = tags.map((res) => {
      return {
        label: res.name,
        value: res.id,
        id: `tag-${res.id}`,
      };
    });
  },
};
</script>
