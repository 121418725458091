<template>
  <s-layout
    full-height
    right-size="250px"
    :right-classlist="['bl-lightest p-3']"
    v-shilp-loader.overlay="deleting"
  >
    <template #default>
      <sp-list
        ref="mediaList"
        endpoint="/v1/media"
        :filters="mediaFilters"
        pagination-mode="internal"
        :attrs="columns"
        v-slot="{ items }"
        :per-page="24"
      >
        <div class="grid grid--4 p-4 g-3 bg-grey">
          <div
            class="radius-3 bg-grey-lightest "
            :key="`media-${index}`"
            v-for="(item, index) in items"
          >
            <div class="p-2">
              <s-media width="100%" :value="item.url" ratio="16x9" />
            </div>

            <div class="bg-light p-2 ">
              <div class="list g-2">
                <h5>{{ item.id }}</h5>
                <h5 class="text-grey">.{{ item.url.split(".").pop() }}</h5>
              </div>
              <div class="flex flex--middle flex--fit mt-2 bt-lightest pt-2">
                <s-button
                  color="primary"
                  theme="muted"
                  size="sm"
                  label="Copy URL"
                  @click.native="$shilp.copy(item.url)"
                />
                <s-button
                  color="danger"
                  theme="muted"
                  size="sm"
                  shape="square"
                  class="ml-auto"
                  icon="vmdi-delete"
                  @click.native="del(item.id)"
                />
              </div>
            </div>
          </div>
        </div>
      </sp-list>
    </template>

    <template #right>
      <s-field label="Upload Public Media">
        <s-media
          v-model="toUpload"
          :readonly="false"
          ratio="16x9"
          :max-file-size="5242480"
        />
      </s-field>
      <s-button
        :disabled="!toUpload"
        @click.native="upload"
        color="primary"
        fluid
        :loader="uploading"
        label="Upload"
        class="mt-2"
      />
    </template>
  </s-layout>
</template>

<script>
import { upload, del } from "@/api/media";
export default {
  data() {
    return {
      uploading: false,
      deleting: false,
      toUpload: null,
      mediaFilters: { privacy: "public" },
      columns: ["id", "url", "srcset", "preview"],
    };
  },
  methods: {
    async upload() {
      try {
        this.uploading = true;
        await upload({ file: this.toUpload, privacy: "public" });
        this.uploading = false;
        this.$refs.mediaList.refresh();
      } catch (err) {
        console.error(err);
      } finally {
        this.toUpload = null;
        this.uploading = false;
      }
    },
    async del(id) {
      this.$shilp.confirm({
        title: "Delete?",
        message: "Sure?",
        ok: async ({ close }) => {
          close();
          try {
            this.deleting = true;
            await del(id);
            this.deleting = false;
            this.$refs.mediaList.refresh();
          } catch (err) {
            console.error(err);
          } finally {
            this.deleting = false;
          }
        },
      });
    },
  },
};
</script>
