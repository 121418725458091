export const timestamp = (date) => {
  return Math.round(new Date(date).getTime() / 1000);
};

export const toDate = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleString().split(",")[0];
};

export const uid = function() {
  return Math.random()
    .toString(36)
    .substr(2, 9);
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
