import Vue from "vue";
import App from "./App.vue";
import router from "./router/";
import store from "./store/";
import "@/scss/style.scss";
import "@/plugins";
import "./global";

//Resets the store when user logs out
const initialState = JSON.parse(JSON.stringify(store.state));
const resetState = () => {
  store.replaceState(JSON.parse(JSON.stringify(initialState)));
};

Vue.config.productionTip = false;

window.Vue = new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    this.$root.$on("logout", () => {
      resetState();
    });
  },
}).$mount("#app");
