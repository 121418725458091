var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"header"}},[(_vm.id != '+')?_c('s-button',{attrs:{"icon":"vmdi-plus","color":"primary","size":"sm","label":"Add Theme"},nativeOn:{"click":function($event){return _vm.theme('+')}}}):_vm._e()],1),_c('s-row',[_c('s-column',{attrs:{"size":2}},[_c('item-form',{ref:"item",staticClass:"p-4",attrs:{"get":_vm.get,"item":_vm.id,"fields":_vm.fields,"save":_vm.save,"del":_vm.del,"redirect":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var form = ref.form;
var creating = ref.creating;
return [_c('s-field-validate',{attrs:{"label":"Name","rules":{ required: creating, moduleName: true }}},[(creating)?_c('s-textbox',{model:{value:(form.name),callback:function ($$v) {_vm.$set(form, "name", $$v)},expression:"form.name"}}):_c('h4',[_vm._v(_vm._s(form.name))])],1),_c('s-field-validate',{attrs:{"rules":{ required: creating },"label":"Component"}},[_c('s-file',{staticClass:"p-0",attrs:{"label":"Select","color":"primary","fluid":""},model:{value:(form.component),callback:function ($$v) {_vm.$set(form, "component", $$v)},expression:"form.component"}})],1),_c('tags',{attrs:{"type":"module"},model:{value:(form.tags),callback:function ($$v) {_vm.$set(form, "tags", $$v)},expression:"form.tags"}}),_c('s-field',{attrs:{"inline":"","label":"Publish"}},[_c('s-switch',{attrs:{"color":"primary","size":"xs","shape":"pill"},model:{value:(form.publish),callback:function ($$v) {_vm.$set(form, "publish", $$v)},expression:"form.publish"}})],1)]}}])})],1),_c('s-column',{attrs:{"size":10}},[(_vm.id != '+')?_c('sp-list',{ref:"list",attrs:{"title":"Themes","endpoint":("v1/admin/modules/" + _vm.id + "/themes"),"attrs":_vm.columns}},[_c('sp-list-table',{scopedSlots:_vm._u([{key:"preview_image",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"b-lightest",attrs:{"width":"400px","src":item.preview_image}})]}},{key:"preview_status",fn:function(ref){
var item = ref.item;
return [_c('node-status',{attrs:{"status":item.preview_status}})]}},{key:"preview_log",fn:function(ref){
var item = ref.item;
return [_c('pre',{staticClass:"text-xs"},[_vm._v("              "+_vm._s(item.preview_log)+"\n            ")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('s-button-group',{staticClass:"flex flex--column flex--fit",attrs:{"size":"sm","color":"primary","theme":"muted","align":"right"}},[_c('s-button',{attrs:{"icon":"vmdi-pencil","label":"Edit Theme"},nativeOn:{"click":function($event){return _vm.theme(item.id)}}}),_c('s-button',{staticClass:"mt-1",attrs:{"href":("/module.html?name=" + _vm.name + "&theme=" + (item.name)),"target":"_blank","icon":"vmdi-open-in-new","label":"Open"}}),_c('s-button',{staticClass:"mt-1",attrs:{"color":"warning","icon":"vmdi-reload","label":"Rebuild Preview"},nativeOn:{"click":function($event){return _vm.buildPreview(item.id)}}})],1)]}}],null,false,1013680118)})],1):_vm._e()],1)],1),_c('s-modal',{attrs:{"id":"add-theme","overlay":"dark","size":"lg"}},[_c('modal',{attrs:{"title":"Add New Theme"}},[_c('item-form',{ref:"item",staticClass:"p-4",attrs:{"get":_vm.getTheme,"item":_vm.themeId,"fields":_vm.themeFields,"save":_vm.saveTheme,"del":_vm.del,"redirect":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var form = ref.form;
return [_c('s-field',{attrs:{"label":"Name"}},[_c('s-textbox',{model:{value:(form.name),callback:function ($$v) {_vm.$set(form, "name", $$v)},expression:"form.name"}})],1),_c('s-field',{attrs:{"label":"Segments JSON"}},[_c('prism-editor',{staticClass:"code-editor radius-2",attrs:{"highlight":_vm.highlighter,"line-numbers":""},model:{value:(form.segments),callback:function ($$v) {_vm.$set(form, "segments", $$v)},expression:"form.segments"}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }