import axios from "@/plugins/axios";

export default () => {
  return axios
    .get("/v1/me")
    .then((res) => res.data)
    .then(({ user }) => {
      const { email, is_leonidas, name } = user;
      return {
        email,
        isAdmin: is_leonidas,
        name,
      };
    });
};
