import axios from "@/plugins/axios";

export const getTheme = (moduleId, id) => {
  return axios
    .get(`v1/admin/modules/${moduleId}/themes/${id}`)
    .then((res) => res.data)
    .then((res) => {
      if (typeof res.segments == "object") {
        res.segments = JSON.stringify(res.segments, null, "\t");
      }
      return res;
    });
};

export const saveTheme = (moduleId, id, data) => {
  if (id) {
    return axios
      .put(`v1/admin/modules/${moduleId}/themes/${id}`, data)
      .then((res) => res.data);
  } else {
    return axios
      .post(`v1/admin/modules/${moduleId}/themes`, data)
      .then((res) => res.data);
  }
};

export const buildPreview = (moduleId, id) => {
  return axios
    .post(`v1/admin/modules/${moduleId}/themes/${id}/preview-build`)
    .then((res) => res.data);
};
