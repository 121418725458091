<template>
  <auth image="login" :error="error">
    <validation-observer v-slot="{ invalid }">
      <form @submit.prevent="login()" class="field-group g-2">
        <s-field-validate
          name="Email"
          rules="required|email"
          before-icon="vmdi-at"
        >
          <s-textbox
            ref="email"
            v-model="form.email"
            type="email"
            placeholder="Email"
          />
        </s-field-validate>

        <s-field
          name="Password"
          rules="required"
          before-icon="vmdi-lock"
          :password-toggle="true"
        >
          <s-textbox
            v-model="form.password"
            type="password"
            placeholder="Password"
          />
        </s-field>

        <s-button
          :disabled="invalid"
          :loader="loading"
          type="submit"
          align="right"
          icon="vmdi-login-variant"
          color="primary"
          size="lg"
          fluid
          label="Login"
        />
      </form>
    </validation-observer>
  </auth>
</template>

<script>
import { login } from "@/api/auth";
import { setToken } from "@/plugins/axios";
export default {
  name: "login",

  components: {
    Auth: require("@/components/Auth").default,
    // ItemForm: require("@/components/ItemForm").default
  },

  data() {
    return {
      error: null,
      loading: false,
      form: {
        email: null,
        password: null,
      },
    };
  },

  mounted() {
    this.$refs.email.$el.focus();
  },

  methods: {
    async login() {
      this.error = null;
      this.loading = true;
      login(this.form)
        .then((res) => {
          localStorage.setItem("token", res.token);
          setToken(res.token);
          this.$router.push("/");
        })
        .catch((err) => {
          this.error = err.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
