<template>
  <p>Logging out...</p>
</template>

<script>
export default {
  beforeCreate() {
    localStorage.removeItem("token");
    this.$root.$emit("logout");
    this.$router.push({ name: "login" });
  }
};
</script>
