import Vue from "vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email, min, regex } from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en";

const rules = { required, email, min, regex };

for (let rule in rules) {
  extend(rule, {
    ...rules[rule], // add the rule
    message: en.messages[rule], // add its message
  });
}

extend("url", {
  validate: (value) => {
    const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
    return value.match(new RegExp(regex));
  },
});

extend("presetName", {
  validate: (value) => {
    const regex = /^[-a-z]+$/;
    if (value.match(new RegExp(regex))) {
      return true;
    } else {
      return "Only lowercase alphabets(a-z) & '-' are allowed.";
    }
  },
});

extend("moduleName", {
  validate: (value) => {
    const regex = /^[-a-z]+$/;
    if (value.match(new RegExp(regex))) {
      return true;
    } else {
      return "Only lowercase alphabets(a-z) & '-' are allowed.";
    }
  },
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
