<template>
  <div>
    <portal to="header">
      <s-button
        color="primary"
        icon="vmdi-plus"
        :to="{ name: 'tag', params: { id: '+' } }"
        size="sm"
        label="Add New"
      />
    </portal>
    <sp-list class="bg-light" endpoint="v1/admin/tags" :attrs="columns">
      <sp-list-table
        sticky-header
        reorder
        @rowClick="$router.push({ name: 'tag', params: { id: $event.id } })"
        @reorder="sort($event)"
      >
        <template #icon="{item}">
          <span class="iconify w-4 h-4 text-grey" :data-icon="item.icon" />
        </template>
      </sp-list-table>
    </sp-list>
  </div>
</template>

<script>
import { sort } from "@/api//tag";

export default {
  data() {
    return {
      columns: [
        { name: "_index", label: "#", fix: true },
        { name: "icon", fix: true },
        { name: "name" },
        { name: "type", fix: true },
        { name: "_drag", fix: true },
      ],
    };
  },

  methods: {
    sort(items) {
      const ids = items.map((item) => item.id);
      sort(ids);
    },
  },
};
</script>
