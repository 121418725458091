<template>
  <div class="auth p-4">
    <div class="card flex__fit">
      <div class="bg-light shadow-6 p-5 radius-5">
        <div class="logo">
          <img class src="@/img/logo.svg" alt />
        </div>
        <item-form-error class="mb-2" :data="error" />
        <slot></slot>
      </div>
      <div class="footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "auth",
  props: {
    image: {
      type: String,
      default: "login",
    },
    error: Object,
  },
  components: {
    ItemFormError: require("@/components/ItemFormError").default,
  },
};
</script>

<style lang="scss" scoped>
.auth {
  height: 100vh;
  background-color: #aa00ff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23ba0de1'/%3E%3Cstop offset='1' stop-color='%23ba0de1' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23ff0074'/%3E%3Cstop offset='1' stop-color='%23ff0074' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23e10d4e'/%3E%3Cstop offset='1' stop-color='%23e10d4e' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23aa00ff'/%3E%3Cstop offset='1' stop-color='%23aa00ff' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23be19c4'/%3E%3Cstop offset='1' stop-color='%23be19c4' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23ff6d00'/%3E%3Cstop offset='1' stop-color='%23ff6d00' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  overflow: auto;
  padding: --space(5) 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  margin-bottom: --space(3);
  padding-bottom: --space(3);
  text-align: center;
  img {
    width: 70%;
  }
}
.card {
  max-width: 400px;
  width: 100%;
}

.footer {
  text-align: center;
}
.wrap {
  height: 100%;
  align-items: stretch;
}
.vector {
  text-align: right;
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: center right;
  img {
    width: 100%;
  }
}

@media screen and (max-width: "575px") {
  .logo {
    img {
      width: 100%;
    }
  }
}
</style>
