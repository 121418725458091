import Vue from "vue";

Vue.component("PageHeader", require("@/components/PageHeader").default);
Vue.component("Modal", require("@/components/Modal").default);

Vue.mixin({
  data() {
    return {
      IS_DEV: process.env.NODE_ENV !== "production",
    };
  },
});
