<template>
  <div>
    <portal to="header">
      <s-button
        color="warning"
        theme="muted"
        class="mr-2"
        icon="vmdi-cog"
        size="sm"
        label="Build All Modules"
        @click.native="buildAllModule()"
      />
      <s-button
        color="warning"
        theme="muted"
        class="mr-2"
        icon="vmdi-image"
        size="sm"
        label="Build All Previews"
        @click.native="buildAllModulePreview()"
      />
      <s-button
        icon="vmdi-plus"
        color="primary"
        size="sm"
        label="Add New"
        :to="{ name: 'module', params: { id: '+' } }"
      />
    </portal>
    <sp-list
      endpoint="v1/admin/modules"
      :per-page="27"
      :attrs="columns"
      :params="params"
      :filters="filters"
      sort-by="updated_at"
      sort-order="desc"
    >
      <template #filters>
        <div class="field-group g-3">
          <s-field size="sm" label="Tag">
            <s-select v-model="filters.tag" :request="tagsList" />
          </s-field>

          <s-field size="sm" label="Published">
            <s-radio
              class="list list--stack g-1"
              :value="
                publishOptions.find((item) => item.apiValue == filters.publish)
                  .value
              "
              @input="filters.publish = arguments[1].apiValue"
              :options="publishOptions"
            />
          </s-field>

          <!-- <s-field :label="`Theme Count (${filters.theme_count || 'Any'})`">
            <div class="flex flex--middle">
              <div class="flex__fluid mr-2">
                <input
                  class="w-100"
                  type="range"
                  v-model="filters.theme_count"
                  min="0"
                  max="10"
                />
              </div>
              <s-button
                class="flex__fit"
                color="danger"
                shape="circle"
                theme="muted"
                size="xs"
                icon="vmdi-close"
                @click.native="filters.theme_count = null"
              />
            </div>
          </s-field> -->
        </div>
      </template>

      <sp-list-table>
        <template #name="{item}">
          <h5 class="text-capitalize">{{ item.name }}</h5>
        </template>

        <template #updated_at="{item}">
          <datetime class="text-xs text-grey" :value="item.updated_at" />
        </template>

        <template #compile_status="{item}">
          <node-status :status="item.compile_status" />
        </template>

        <template #tags="{item}">
          <s-button
            v-for="(tag, index) in item.tags"
            :label="tag.name"
            :key="`${index}-${tag.id}`"
            color="grey"
            theme="muted"
            shape="pill"
            size="xs"
            align="left"
            class="mb-1"
            badge
          >
            <template #icon>
              <div class="button__icon">
                <span class="iconify" :data-icon="tag.icon" />
              </div>
            </template>
          </s-button>
        </template>

        <template #themes="{item}">
          <s-button
            v-for="(theme, index) in item.themes"
            :label="theme.name"
            :key="`${index}-${theme.id}`"
            :href="`/module.html?name=${item.name}&theme=${theme.name}`"
            target="_blank"
            color="grey"
            theme="link"
            shape="pill"
            align="left"
            size="xs"
            class="mb-1"
          />
        </template>

        <template #preview_image="{item}">
          <img
            v-if="item.themes && item.themes.default"
            width="300px"
            :src="item.themes.default.preview_image"
          />
        </template>

        <template #publish="{item}">
          <h4>{{ item.publish ? "🌏" : "🔒" }}</h4>
        </template>

        <template #actions="{item}">
          <div class="flex flex--column flex--fit">
            <s-button
              size="sm"
              color="grey"
              :to="{ name: 'module', params: { id: item.id } }"
              icon="vmdi-pencil"
              theme="muted"
              align="right"
              shape="circle"
            />

            <s-button
              class="mt-1"
              size="sm"
              color="grey"
              :href="`/module.html?name=${item.name}`"
              target="_blank"
              icon="vmdi-open-in-new"
              theme="muted"
              align="right"
              shape="circle"
            />
          </div>
        </template>
      </sp-list-table>
    </sp-list>
  </div>
</template>

<script>
import { getTags } from "@/api/tag";

export default {
  props: {
    id: String,
  },
  components: {
    Datetime: require("@/components/Datetime").default,
    NodeStatus: require("@/components/NodeStatus").default,
  },
  data() {
    return {
      publishOptions: [
        {
          label: "All",
          value: "all",
          apiValue: undefined,
        },
        {
          label: "Published",
          value: "published",
          apiValue: true,
        },
        {
          label: "Unpublished",
          value: "unpublished",
          apiValue: false,
        },
      ],
      columns: [
        { name: "name", sortable: true },
        { name: "preview_image", fix: true },
        { name: "version", fix: true, fix: true },
        { name: "compile_status", fix: true },
        { name: "tags", fix: true },
        { name: "themes", fix: true },
        { name: "updated_at", fix: true, sortable: true },
        { name: "publish", fix: true },
        { name: "actions", fix: true },
      ],
      params: {
        include: "themes",
      },
      open: null,
      filters: {
        tag: null,
        publish: undefined,
      },
    };
  },

  methods: {
    tagsList() {
      return getTags("module").then((res) => {
        return res.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      });
    },

    buildAllModulePreview() {
      const sure = confirm("Are you sure you want to do this for all modules?");
      if (!sure) return;
      this.$axios.post("/v1/admin/modules/preview-build").then((res) => {
        this.$shilp.notify({
          type: "info",
          message: res.data.message,
          title: "Success",
        });
      });
    },

    buildAllModule() {
      const sure = confirm("Are you sure you want to do this for all modules?");
      if (!sure) return;
      this.$axios.post("/v1/admin/modules/build").then((res) => {
        this.$shilp.notify({
          type: "info",
          message: res.data.message,
          title: "Success",
        });
      });
    },
  },
};
</script>
