<template>
  <div class="h-100" id="app">
    <s-modal-container />
    <s-copy />
    <s-confirm />
    <s-notification-container top="50px" right="50px" />
    <router-view v-if="ready" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      ready: false
    };
  },
  created() {
    this.setTitle();
  },
  mounted() {
    this.ready = true;
  },
  watch: {
    $route() {
      this.setTitle();
    }
  },

  methods: {
    setTitle() {
      const title = this.$route.meta?.label;
      document.title = title ? `${title} | PageMaker` : "PageMaker";
    }
  }
};
</script>
