<template>
  <s-alert
    class="gx-2 gy-1"
    v-if="data"
    icon="vmdi-alert"
    color="danger"
    theme="muted"
    fluid
    :title="parsed.message"
  >
    <template v-for="field in parsed.errors">
      <template v-if="Array.isArray(field)">
        <p v-for="(error, index) in field" :key="`${field}-${index}`">
          {{ error }}
        </p>
      </template>
      <template v-else>
        <p :key="`${field}`">{{ field }}</p>
      </template>
    </template>
  </s-alert>
</template>

<script>
export default {
  props: ["data"],

  computed: {
    parsed() {
      let message;
      let errors;
      if (this.data) {
        if (this.data.errors) {
          message = "Oops!";
          errors = this.data.errors;
        } else if (this.data.error) {
          message = "Oops!";
          errors = [this.data.error];
        } else if (this.data.message) {
          message = "Oops!";
          errors = [this.data.message];
        } else {
          message = "Unknown API Error!";
          errors = null;
        }
      } else {
        message = "Unknown Error!";
        errors = null;
      }
      return {
        message,
        errors
      };
    }
  }
};
</script>
