var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"header"}},[_c('s-button',{staticClass:"mr-2",attrs:{"color":"warning","theme":"muted","icon":"vmdi-cog","size":"sm","label":"Build All Modules"},nativeOn:{"click":function($event){return _vm.buildAllModule()}}}),_c('s-button',{staticClass:"mr-2",attrs:{"color":"warning","theme":"muted","icon":"vmdi-image","size":"sm","label":"Build All Previews"},nativeOn:{"click":function($event){return _vm.buildAllModulePreview()}}}),_c('s-button',{attrs:{"icon":"vmdi-plus","color":"primary","size":"sm","label":"Add New","to":{ name: 'module', params: { id: '+' } }}})],1),_c('sp-list',{attrs:{"endpoint":"v1/admin/modules","per-page":27,"attrs":_vm.columns,"params":_vm.params,"filters":_vm.filters,"sort-by":"updated_at","sort-order":"desc"},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('div',{staticClass:"field-group g-3"},[_c('s-field',{attrs:{"size":"sm","label":"Tag"}},[_c('s-select',{attrs:{"request":_vm.tagsList},model:{value:(_vm.filters.tag),callback:function ($$v) {_vm.$set(_vm.filters, "tag", $$v)},expression:"filters.tag"}})],1),_c('s-field',{attrs:{"size":"sm","label":"Published"}},[_c('s-radio',{staticClass:"list list--stack g-1",attrs:{"value":_vm.publishOptions.find(function (item) { return item.apiValue == _vm.filters.publish; })
                .value,"options":_vm.publishOptions},on:{"input":function($event){_vm.filters.publish = arguments[1].apiValue}}})],1)],1)]},proxy:true}])},[_c('sp-list-table',{scopedSlots:_vm._u([{key:"name",fn:function(ref){
                var item = ref.item;
return [_c('h5',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.name))])]}},{key:"updated_at",fn:function(ref){
                var item = ref.item;
return [_c('datetime',{staticClass:"text-xs text-grey",attrs:{"value":item.updated_at}})]}},{key:"compile_status",fn:function(ref){
                var item = ref.item;
return [_c('node-status',{attrs:{"status":item.compile_status}})]}},{key:"tags",fn:function(ref){
                var item = ref.item;
return _vm._l((item.tags),function(tag,index){return _c('s-button',{key:(index + "-" + (tag.id)),staticClass:"mb-1",attrs:{"label":tag.name,"color":"grey","theme":"muted","shape":"pill","size":"xs","align":"left","badge":""},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('div',{staticClass:"button__icon"},[_c('span',{staticClass:"iconify",attrs:{"data-icon":tag.icon}})])]},proxy:true}],null,true)})})}},{key:"themes",fn:function(ref){
                var item = ref.item;
return _vm._l((item.themes),function(theme,index){return _c('s-button',{key:(index + "-" + (theme.id)),staticClass:"mb-1",attrs:{"label":theme.name,"href":("/module.html?name=" + (item.name) + "&theme=" + (theme.name)),"target":"_blank","color":"grey","theme":"link","shape":"pill","align":"left","size":"xs"}})})}},{key:"preview_image",fn:function(ref){
                var item = ref.item;
return [(item.themes && item.themes.default)?_c('img',{attrs:{"width":"300px","src":item.themes.default.preview_image}}):_vm._e()]}},{key:"publish",fn:function(ref){
                var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.publish ? "🌏" : "🔒"))])]}},{key:"actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"flex flex--column flex--fit"},[_c('s-button',{attrs:{"size":"sm","color":"grey","to":{ name: 'module', params: { id: item.id } },"icon":"vmdi-pencil","theme":"muted","align":"right","shape":"circle"}}),_c('s-button',{staticClass:"mt-1",attrs:{"size":"sm","color":"grey","href":("/module.html?name=" + (item.name)),"target":"_blank","icon":"vmdi-open-in-new","theme":"muted","align":"right","shape":"circle"}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }