<template>
  <div
    class="sidebar flex flex--column h-100 flex--nowrap"
    :class="{ 'sidebar--collapse flex--center': collapse }"
  >
    <!-- LOGO -->
    <div v-if="collapse" class="flex__fit logo text-center pt-2">
      <img width="28px" :src="require('@/img/logo-icon.svg')" />
    </div>
    <div v-else class="flex__fit logo px-4 pt-3">
      <img width="100%" :src="require('@/img/logo-text-light.svg')" />
    </div>

    <!-- NAV -->
    <s-nav
      class="sidebar__nav mt-3"
      fluid
      align="left"
      :class="collapse ? 'flex--middle' : 'px-3'"
      :shape="collapse ? 'square' : null"
    >
      <s-nav-item
        icon="vmdi-toy-brick"
        :to="{ name: 'modules' }"
        label="Modules"
      />
      <s-nav-item
        icon="vmdi-folder-multiple-image"
        :to="{ name: 'media' }"
        label="Media"
      />
      <s-nav-item
        icon="vmdi-creation"
        :to="{ name: 'presets' }"
        label="Presets"
      />
      <s-nav-item
        icon="vmdi-desktop-mac-dashboard"
        :to="{ name: 'templates' }"
        label="Templates"
      />
      <s-nav-item icon="vmdi-tag" :to="{ name: 'tags' }" label="Tags" />
      <s-nav-item
        icon="vmdi-account-group"
        :to="{ name: 'users' }"
        label="Users"
      />
      <s-nav-item
        icon="vmdi-briefcase"
        :to="{ name: 'campaigns' }"
        label="Campaigns"
      />
    </s-nav>

    <!-- USER -->
    <div
      v-if="!collapse"
      class="flex__fit user item item--middle item--fluid p-2"
    >
      <div
        class="
          item__media
          radius-circle
          flex flex--middle flex--center flex--fit
          w-5
          h-5
          mr-2
          bg-grey-darker
        "
      >
        <s-icon class="text-grey" name="vmdi-account-circle" />
      </div>
      <small class="item__title text-grey">Hello!</small>
      <h5 class="item__body lh-0 text-light">{{ user.name }}</h5>
    </div>

    <s-button-group
      :shape="collapse ? 'square' : null"
      align="left"
      class="flex__fit text-center"
    >
      <s-button v-if="collapse" icon="vmdi-account-circle" />
      <s-button
        :icon="
          collapse ? 'vmdi-arrow-collapse-right' : 'vmdi-arrow-collapse-left'
        "
        label="Collapse"
        @click.native="$emit('collapse', !collapse)"
      />
      <div class="version">
        <span>{{ version }}</span>
      </div>
    </s-button-group>
  </div>
</template>

<script>
import { version } from "../../package.json";
import { mapState } from "vuex";
export default {
  props: {
    collapse: Boolean,
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      version,
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  background-color: #111827;
}
.logo {
  flex: 0 0 auto;
}
.user {
  background-color: #263238;
  text-decoration: none;
}
.sidebar__nav {
  overflow: auto;
  flex: 1 1 auto;
  flex-direction: column;
}

.sidebar--collapse::v-deep {
  .user {
    display: flex;
    justify-content: center;
  }
  .button__label {
    display: none;
  }
}
::v-deep {
  .button {
    --button--color: #{--color(grey)};
    --button--hover-color: #{--color(grey, light)};
  }
  .button--active {
    --button--bg: #{--color(grey, darkest)};
    --button--color: #{--color(light)};
    --button--hover-bg: #{--color(grey, darkest)};
    --button--hover-color: #{--color(light)};
  }
}
.version {
  font-size: 11px;
  padding: 8px 0px 8px 0px;
  color: #{--color(grey)};
  text-align: center;
}
</style>
