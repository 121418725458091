export default [
  {
    path: "",
    name: "admin",
    redirect: "modules",
  },
  {
    path: "media",
    name: "media",
    component: require("@/views/media/List.vue").default,
    meta: {
      label: "Media",
      breadcrumbs: ["admin", "media"],
    },
  },
  {
    path: "templates",
    name: "templates",
    component: require("@/views/template/List.vue").default,
    meta: {
      label: "Templates",
      breadcrumbs: ["admin", "templates"],
    },
  },
  {
    path: "templates/:id",
    name: "template",
    component: require("@/views/template/Item.vue").default,
    props: true,
    meta: {
      label: "Template",
      breadcrumbs: ["admin", "templates", "template"],
    },
  },
  {
    path: "modules",
    name: "modules",
    component: require("@/views/module/List.vue").default,
    meta: {
      label: "Modules",
      breadcrumbs: ["admin", "modules"],
    },
  },
  {
    path: "modules/:id",
    name: "module",
    component: require("@/views/module/Item.vue").default,
    props: true,
    meta: {
      label: "Modules",
      breadcrumbs: ["admin", "modules", "module"],
    },
  },
  {
    path: "tags",
    name: "tags",
    component: require("@/views/tag/List.vue").default,
    meta: {
      label: "Tags",
      breadcrumbs: ["admin", "tags"],
    },
  },
  {
    path: "tags/:id",
    name: "tag",
    component: require("@/views/tag/Item.vue").default,
    props: true,
    meta: {
      label: "Tag",
      breadcrumbs: ["admin", "tags", "tag"],
    },
  },
  {
    path: "presets",
    name: "presets",
    component: require("@/views/preset/List.vue").default,
    meta: {
      label: "Presets",
      breadcrumbs: ["admin", "presets"],
    },
  },
  {
    path: "presets/:id",
    name: "preset",
    component: require("@/views/preset/Item.vue").default,
    props: true,
    meta: {
      label: "Preset",
      breadcrumbs: ["admin", "presets", "preset"],
    },
  },
  {
    path: "users",
    name: "users",
    component: require("@/views/users/List.vue").default,
    props: true,
    meta: {
      label: "Users",
      breadcrumbs: ["admin", "users"],
    },
  },
  {
    path: "campaigns",
    name: "campaigns",
    component: require("@/views/campaign/List.vue").default,
    props: true,
    meta: {
      label: "Campaigns",
      breadcrumbs: ["admin", "campaigns"],
    },
  },
];
