<template>
  <s-button
    v-bind="$attrs"
    :color="value.color"
    :label="status || 'unknown'"
    size="xs"
    shape="pill"
    theme="muted"
    badge
    :emoji="value.emoji"
  />
</template>

<script>
export default {
  props: {
    status: String,
  },
  data() {
    return {
      list: {
        building: {
          color: "warning",
          emoji: "🚧",
        },
        success: {
          color: "success",
          emoji: "✅",
        },
        error: {
          color: "error",
          emoji: "🔴",
        },
        unknown: {
          color: "grey",
          emoji: "👀",
        },
      },
    };
  },

  computed: {
    value() {
      if (!this.status) return this.list.unknown;
      return this.list[this.status] || this.list.unknown;
    },
  },
};
</script>
