import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import hydrate from "./modules/hydrate";
import root from "./root";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["pref"],
});

Vue.use(Vuex);

const store = new Vuex.Store({
  ...root,
  modules: {
    hydrate,
  },
  plugins: [vuexLocal.plugin],
});

//Resets the store when user logs out
const resetStore = () => {
  store.replaceState(JSON.parse(JSON.stringify(initialState)));
};

const initialState = JSON.parse(JSON.stringify(store.state));
export default store;
export { resetStore };
