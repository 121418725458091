import axios from "@/plugins/axios";

export const get = (id) => {
  return axios.get(`v1/admin/modules/${id}`).then((res) => res.data);
};

export const del = (id) => {
  return axios.delete(`v1/admin/modules/${id}`).then((res) => res.data);
};

export const save = (id, data) => {
  if (id) {
    return axios.put(`v1/admin/modules/${id}`, data).then((res) => res.data);
  } else {
    return axios.post(`v1/admin/modules/`, data).then((res) => res.data);
  }
};

export const getTheme = (moduleName, themeName) => {
  return axios
    .get(`v1/admin/modules-by-name/${moduleName}/themes/${themeName}`)
    .then((res) => res.data);
};
