var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"header"}},[_c('s-button',{attrs:{"color":"primary","icon":"vmdi-plus","to":{ name: 'preset', params: { id: '+' } },"size":"sm","label":"Add New"}})],1),_c('sp-list',{staticClass:"bg-light",attrs:{"endpoint":"v1/admin/segment-preset","attrs":_vm.columns,"params":{ include: 'segment' }}},[_c('sp-list-table',{attrs:{"sticky-header":""},scopedSlots:_vm._u([{key:"icon",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"iconify w-4 h-4 text-grey",attrs:{"data-icon":item.icon}})]}},{key:"preview",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.preview_image,"alt":"","width":item.preview_image_width / 2}})]}},{key:"tags",fn:function(ref){
var item = ref.item;
return [_vm._l((item.tags),function(tag){return [_c('s-button',{key:("tag-" + (tag.id)),attrs:{"label":tag.name,"color":"grey","theme":"muted","shape":"pill","size":"xs","badge":""}})]})]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('s-button',{attrs:{"icon":"vmdi-pencil","color":"grey","theme":"muted","shape":"circle","to":{ name: 'preset', params: { id: item.id } }}}),_c('s-button',{staticClass:"ml-1",attrs:{"icon":"vmdi-content-copy","color":"grey","theme":"muted","shape":"pill","label":"ID"},nativeOn:{"click":function($event){return _vm.$shilp.copy(item.preset_id)}}}),_c('s-button',{staticClass:"ml-1",attrs:{"icon":"vmdi-content-copy","color":"grey","theme":"muted","shape":"pill","label":"Props"},nativeOn:{"click":function($event){_vm.$shilp.copy(JSON.stringify(item.data, null, '\t'))}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }